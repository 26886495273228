import moment from "moment";

export const parseDate = (date, dateFormat = "MM/DD/YYYY, h:mm:ss A") => {
  if (!date) {
    return "--";
  }
  try {
    if (date + "".endsWith("Z")) {
      return moment(date).utc().format(dateFormat);
    }
    return new Date(date).toLocaleDateString("en-US", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    });
  } catch (e) {
    return "--";
  }
};

export const parseInputDate = (date) => {
  if (!date) {
    return null;
  }
  try {
    const parseDate = moment(date, "MMMM DD, YYYY");
    return parseDate.format("YYYY-MM-DD");
  } catch (e) {
    return null;
  }
};

export const parseInputDateValue = (date) => {
  if (!date) {
    return null;
  }
  try {
    const parseDate = moment(date, "YYYY-MM-DD");
    return parseDate.toDate();
  } catch (e) {
    return null;
  }
};

export const unixTimestampToDateStr = (unixTimestamp) => {
  const date = new Date(unixTimestamp); // No need to multiply by 1000
  const year = date.getFullYear();
  const month = ("0" + (date.getMonth() + 1)).slice(-2); // getMonth() is zero-indexed, so we must add 1
  const day = ("0" + date.getDate()).slice(-2); // pad single digit dates with a leading 0
  return `${year}-${month}-${day}`;
};
