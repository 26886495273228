export const ADDRESS_REGEX = /^[a-zA-Z0-9_/.~=[\]*$&",';:#`-\s]{1,35}$/;
export const CITY_REGEX = /^[a-zA-Z\s]+$/;
export const ZIP_REGEX = /^\d{4,8}$/;
export const VIN_REGEX = /^[A-Za-z0-9]{17}$/;
export const MAKE_REGEX = /^[A-Za-z\s-]+[A-Za-z\s-]$/;
export const MODEL_REGEX = /^[A-Za-z0-9\s-/.]+[A-Za-z0-9\s-/.]$/;
export const NAME_REGEX =
  /^[a-zA-ZàèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ¿¡’' ]+[a-zA-ZàèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ¿¡’' ]$/;
export const NAME_REGEX_ATP =
  /^[a-zA-ZàèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ¿¡’'\- ]+[a-zA-ZàèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ¿¡’' ]$/;
export const PHONE_REGEX = /^\d{10}$/;
export const EMAIL_REGEX = /^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/;
export const EMAIL_REGEX_C360 =
  /^([a-zA-ZàèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ¿¡0-9_\-.’']+)@([a-zA-ZàèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ¿¡0-9_\-.]+)\.([a-zA-ZàèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ¿¡0-9]+)$/;
export const DURATION_REGEX = /^[0-9]{0,2}$/;
export const ZIP_CODE_LIMIT = 8;
