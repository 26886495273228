import { getRequest } from "../../../services/RestServices";
import * as urls from "../../api/scheduler/url";

export const getDropDownData = async (so_Id) => {
  try {
    return await getRequest(`${urls.GET_JOBS_Dropdown}/${so_Id}`);
  } catch (err) {
    return err;
  }
};
