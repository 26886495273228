import { Body } from "@vds/typography";
import { FlexColumn } from "../../../components/core/Utility";

const ColumnData = ({ values, bold = false, padding = "0px" }) => {
  return (
    <>
      <FlexColumn paddingRight={padding}>
        {values.map((data, index) => {
          return (
            <>
              <Body key={index} bold={bold} size="large">
                {data || <span>&nbsp;</span>}
              </Body>
              <div style={{ height: "4px" }} />
            </>
          );
        })}
      </FlexColumn>
    </>
  );
};

export default ColumnData;
