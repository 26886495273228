//Aggregator Endpoints

const aggregatorUrl = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_AGGREGATOR}`;

export const CheckStatus = `${aggregatorUrl}/checkstatus`;

export const UPLOADDATA = `${aggregatorUrl}/devices`;
export const UPLOADUNITS = `${aggregatorUrl}/devices/batch/db/history`;
export const UPLOADREFURBISHED = `${aggregatorUrl}/devices/uploadrefurbisheddata`;

export const VIEWBATCH = `${aggregatorUrl}/viewbatch`;
export const MODIFYBATCH = `${aggregatorUrl}/modifybatch`;
export const GETLOOKUPDATA = `${aggregatorUrl}/get-unassign-camera-esn`;
export const SENDTOREVEAL = `${aggregatorUrl}/sendtoreveal`;
export const UPDATEBATCH = `${aggregatorUrl}/devices/updatebatch`;
export const MANUAL_ACTIVATION = `${aggregatorUrl}/devices/manualActivation`;
