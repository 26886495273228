import { COLUMN_WIDTHS } from "../../constants/ApplicationConstants";
import { errorBody } from "./errorModal";

export const vehicleColumns = [
  {
    key: "ESN",
    value: "esn",
    filterType: "String",
    isFilterEnabled: true,
    isSortable: true,
    width: COLUMN_WIDTHS.small,
  },
  {
    key: "Technical Reference Id",
    value: "technicalRefId",
    filterType: "String",
    isFilterEnabled: true,
    isSortable: true,
    width: COLUMN_WIDTHS.small,
  },
  {
    key: "Status",
    value: "status",
    filterType: "String",
    isFilterEnabled: true,
    isSortable: true,
    width: "100px",
  },
  {
    key: "Inbound Shipment Date",
    value: "inboundShipmentDate",
    filterType: "Date",
    isFilterEnabled: true,
    isSortable: true,
    width: COLUMN_WIDTHS.small,
    isCustomFilter: true,
    isDate: true,
  },
  {
    key: "Activation  Date",
    value: "activationDate",
    filterType: "Date",
    isFilterEnabled: true,
    isSortable: true,
    width: COLUMN_WIDTHS.small,
    isCustomFilter: true,
    isDate: true,
  },
  {
    key: "Vehicle Creation  Date",
    value: "vehicleCreationDate",
    filterType: "Date",
    isFilterEnabled: true,
    isSortable: true,
    width: COLUMN_WIDTHS.small,
    isCustomFilter: true,
    isDate: true,
  },

  {
    key: "Outbound Shipment Date",
    value: "outboundShipmentDate",
    filterType: "Date",
    isFilterEnabled: true,
    isSortable: true,
    width: COLUMN_WIDTHS.small,
    isCustomFilter: true,
    isDate: true,
  },
  {
    key: "Vehicle Tracking Id",
    value: "vehicleTrackingId",
    filterType: "String",
    isFilterEnabled: true,
    isSortable: true,
    width: COLUMN_WIDTHS.small,
  },
  {
    key: "Install Date",
    value: "installDate",
    filterType: "Date",
    isFilterEnabled: true,
    isSortable: true,
    width: COLUMN_WIDTHS.small,
    isCustomFilter: true,
    isDate: true,
  },
  {
    key: "Billing Start Date",
    value: "billingStartDate",
    filterType: "Date",
    isFilterEnabled: true,
    isSortable: true,
    width: COLUMN_WIDTHS.small,
    isCustomFilter: true,
    isDate: true,
  },
  {
    key: "Workticket Number",
    value: "workTicketNumber",
    filterType: "String",
    isFilterEnabled: true,
    isSortable: true,
    width: COLUMN_WIDTHS.small,
  },
  {
    key: "Is Self Checkin",
    filterType: "String",
    isFilterEnabled: true,
    isSortable: true,
    value: "selfCheckIn",
    width: COLUMN_WIDTHS.small,
  },
  {
    key: "Installation Method",
    value: "installationMethod",
    filterType: "String",
    isFilterEnabled: true,
    isSortable: true,
    width: COLUMN_WIDTHS.small,
  },
  {
    key: "Phone Number",
    value: "phoneNumber",
    filterType: "String",
    isFilterEnabled: true,
    isSortable: true,
    width: COLUMN_WIDTHS.small,
  },
  {
    key: "SFDC Case Number",
    value: "sfdcCaseNumber",
    filterType: "String",
    isFilterEnabled: true,
    isSortable: true,
    width: COLUMN_WIDTHS.small,
  },
  {
    key: "Modified At",
    value: "modifiedAt",
    filterType: "Date",
    isFilterEnabled: true,
    isSortable: true,
    width: COLUMN_WIDTHS.small,
    isCustomFilter: true,
    isDate: true,
  },
  {
    key: "Modified By",
    value: "modifiedBy",
    filterType: "String",
    isFilterEnabled: true,
    isSortable: true,
    width: COLUMN_WIDTHS.small,
  },
  {
    key: "Error Message",
    filterType: "String",
    isFilterEnabled: true,
    isSortable: true,
    value: "errorMessage",
    width: COLUMN_WIDTHS.small,
    customBodyCallback: errorBody,
  },
];
export const cameraColumns = [
  {
    key: "ESN",
    value: "esn",
    filterType: "String",
    isFilterEnabled: true,
    isSortable: true,
    width: COLUMN_WIDTHS.small,
  },
  {
    key: "Technical Reference Id",
    value: "technicalRefId",
    filterType: "String",
    isFilterEnabled: true,
    isSortable: true,
    width: COLUMN_WIDTHS.small,
  },
  {
    key: "VTU ESN",
    value: "vtuESN",
    filterType: "String",
    isFilterEnabled: true,
    isSortable: true,
    width: COLUMN_WIDTHS.small,
  },
  {
    key: "Status",
    value: "status",
    filterType: "String",
    isFilterEnabled: true,
    isSortable: true,
    width: COLUMN_WIDTHS.small,
  },
  {
    key: "Inbound Shipment Date",
    value: "inboundShipmentDate",
    filterType: "Date",
    isFilterEnabled: true,
    isSortable: true,
    width: COLUMN_WIDTHS.small,
    isCustomFilter: true,
    isDate: true,
  },
  {
    key: "Activation  Date",
    value: "activationDate",
    filterType: "Date",
    isFilterEnabled: true,
    isSortable: true,
    width: COLUMN_WIDTHS.small,
    isCustomFilter: true,
    isDate: true,
  },
  {
    key: "Camera Creation  Date",
    value: "revealCameraCreationDate",
    filterType: "Date",
    isFilterEnabled: true,
    isSortable: true,
    width: COLUMN_WIDTHS.small,
    isCustomFilter: true,
    isDate: true,
  },
  {
    key: "Outbound Shipment Date",
    value: "outboundShipmentDate",
    filterType: "Date",
    isFilterEnabled: true,
    isSortable: true,
    width: COLUMN_WIDTHS.small,
    isCustomFilter: true,
    isDate: true,
  },
  {
    key: "Install Date",
    value: "installDate",
    filterType: "Date",
    isFilterEnabled: true,
    isSortable: true,
    width: COLUMN_WIDTHS.small,
    isCustomFilter: true,
    isDate: true,
  },
  {
    key: "Billing Start Date",
    value: "billingStartDate",
    filterType: "Date",
    isFilterEnabled: true,
    isSortable: true,
    width: COLUMN_WIDTHS.small,
    isCustomFilter: true,
    isDate: true,
  },
  {
    key: "Workticket Number",
    value: "workTicketNumber",
    filterType: "String",
    isFilterEnabled: true,
    isSortable: true,
    width: COLUMN_WIDTHS.small,
  },
  {
    key: "Is Self Checkin",
    value: "selfCheckIn",
    filterType: "String",
    isFilterEnabled: true,
    isSortable: true,
    width: COLUMN_WIDTHS.small,
  },
  {
    key: "Installation Method",
    value: "installationMethod",
    filterType: "String",
    isFilterEnabled: true,
    isSortable: true,
    width: COLUMN_WIDTHS.small,
  },
  {
    key: "Phone Number",
    value: "phoneNumber",
    filterType: "String",
    isFilterEnabled: true,
    isSortable: true,
    width: COLUMN_WIDTHS.small,
  },
  {
    key: "SFDC Case Number",
    value: "sfdcCaseNumber",
    filterType: "String",
    isFilterEnabled: true,
    isSortable: true,
    width: COLUMN_WIDTHS.small,
  },
  {
    key: "Modified At",
    value: "modifiedAt",
    filterType: "Date",
    isFilterEnabled: true,
    isSortable: true,
    width: COLUMN_WIDTHS.small,
    isCustomFilter: true,
    isDate: true,
  },
  {
    key: "Modified By",
    value: "modifiedBy",
    filterType: "String",
    isFilterEnabled: true,
    isSortable: true,
    width: COLUMN_WIDTHS.small,
  },
  {
    key: "Error Message",
    filterType: "String",
    isFilterEnabled: true,
    isSortable: true,
    value: "errorMessage",
    width: COLUMN_WIDTHS.small,
    customBodyCallback: errorBody,
  },
];

/**
 * Will be used in PDF and excel headers exports
 */

export const cameraColumnsForDownloads = [
  {
    key: "ESN",
    label: "ESN",
    value: "esn",
  },
  {
    key: "Technical Reference Id",
    label: "Technical Reference Id",
    value: "technicalRefId",
  },
  {
    key: "Status",
    label: "Status",
    value: "status",
  },
  {
    key: "State",
    label: "State",
    value: "state",
  },
  {
    key: "Inbound Shipment Date",
    label: "Inbound Shipment Date",
    value: "inboundShipmentDate",
  },
  {
    key: "Activation  Date",
    label: "Activation  Date",
    value: "activationDate",
  },
  {
    key: "Vehicle Creation  Date",
    label: "Vehicle Creation  Date",
    value: "vehicleCreationDate",
  },
  {
    key: "Outbound Shipment Date",
    label: "Outbound Shipment Date",
    value: "outboundShipmentDate",
  },
  {
    key: "Vehicle Tracking Id",
    label: "Vehicle Tracking Id",
    value: "vehicleTrackingId",
  },
  {
    key: "Install Date",
    label: "Install Date",
    value: "installDate",
  },
  {
    key: "Billing Start Date",
    label: "Billing Start Date",
    value: "billingStartDate",
  },
  {
    key: "Workticket Number",
    label: "Workticket Number",
    value: "workTicketNumber",
  },
  {
    key: "Is Self Checkin",
    label: "Is Self Checkin",
    value: "String",
  },
  {
    key: "Installation Method",
    label: "Installation Method",
    value: "installationMethod",
  },
  {
    key: "Phone Number",
    label: "Phone Number",
    value: "phoneNumber",
  },
  {
    key: "SFDC Case Number",
    label: "SFDC Case Number",
    value: "sfdcCaseNumber",
  },
  {
    key: "Modified At",
    label: "Modified At",
    value: "modifiedAt",
  },
  {
    key: "Modified By",
    label: "Modified By",
    value: "modifiedBy",
  },
  {
    key: "Location",
    label: "Location",
    value: "location",
  },
  {
    key: "Carrier",
    label: "Carrier",
    value: "carrier",
  },
  {
    key: "Box Model",
    label: "Box Model",
    value: "boxModel",
  },
  {
    key: "IMEI",
    label: "IMEI",
    value: "imei",
  },
  {
    key: "ICCID",
    label: "ICCID",
    value: "iccid",
  },
  {
    key: "MEID",
    label: "MEID",
    value: "meid",
  },
  {
    key: "Vehicle Reg Number",
    label: "Vehicle Reg Number",
    value: "vehicleRegNumber",
  },
  {
    key: "Vehicle Label",
    label: "Vehicle Label",
    value: "vehicleLabel",
  },
  {
    key: "Unit Type",
    label: "Unit Type",
    value: "unitType",
  },
  {
    key: "Last Reported Date",
    label: "Last Reported Date",
    value: "lastReportedDate",
  },
  {
    key: "FirstReportedDate",
    label: "FirstReportedDate",
    value: "firstReportedDate",
  },
  {
    key: "Carrier Rate Plan",
    label: "Carrier Rate Plan",
    value: "carrierRatePlan",
  },
  {
    key: "Batch Number",
    label: "Batch Number",
    value: "batchNumber",
  },
  {
    key: "Batch File Name",
    label: "Batch File Name",
    value: "batchFileName",
  },
  {
    key: "Serial Num",
    label: "Serial Num",
    value: "serialNum",
  },
  {
    key: "Box Name",
    label: "Box Name",
    value: "boxName",
  },
  {
    key: "Sensor Names",
    label: "Sensor Names",
    value: "sensorNames",
  },
  {
    key: "VIN",
    label: "VIN",
    value: "vin",
  },
  {
    key: "Vehicle Serial Number",
    label: "Vehicle Serial Number",
    value: "vehicleSerialNumber",
  },
  {
    key: "Vehicle Year",
    label: "Vehicle Year",
    value: "vehicleYear",
  },
  {
    key: "Vehicle Make",
    label: "Vehicle Make",
    value: "vehicleMake",
  },
  {
    key: "Vehicle Model",
    label: "Vehicle Model",
    value: "vehicleModel",
  },
  {
    key: "Odometer",
    label: "Odometer",
    value: "odometer",
  },
  {
    key: "Odometer Units",
    label: "Odometer Units",
    value: "odometerUnits",
  },
  {
    key: "Engine On",
    label: "Engine On",
    value: "engineOn",
  },
  {
    key: "Fuel Type",
    label: "Fuel Type",
    value: "fuelType",
  },
  {
    key: "Vehicle Size",
    label: "Vehicle Size",
    value: "vehicleSize",
  },
  {
    key: "Account UID",
    label: "Account UID",
    value: "accountUID",
  },
  {
    key: "Business Partner ID",
    label: "Business Partner ID",
    value: "businessPartnerID",
  },
  {
    key: "Sales Order Number",
    label: "Sales Order Number",
    value: "salesOrderNumber",
  },
  {
    key: "Work Order Number",
    label: "Work Order Number",
    value: "workOrderNumber",
  },
  {
    key: "Purchase Order Number",
    label: "Purchase Order Number",
    value: "purchaseOrderNumber",
  },
  {
    key: "Activation Requested On",
    label: "Activation Requested On",
    value: "activationRequestedOn",
  },
  {
    key: "Installation Notes",
    label: "Installation Notes",
    value: "installationNotes",
  },
  {
    key: "Check In Validated",
    label: "Check In Validated",
    value: "checkInValidated",
  },
  {
    key: "Check In Complete",
    label: "Check In Complete",
    value: "checkInComplete",
  },
  {
    key: "Last Reported From",
    label: "Last Reported From",
    value: "lastReportedFrom",
  },
  {
    key: "Account Name",
    label: "Account Name",
    value: "accountName",
  },
  {
    key: "Installer Name",
    label: "Installer Name",
    value: "installerName",
  },
  {
    key: "Installer Email",
    label: "Installer Email",
    value: "installerEmail",
  },
  {
    key: "Installation Company",
    label: "Installation Company",
    value: "installationCompany",
  },
  {
    key: "Installed By",
    label: "Installed By",
    value: "installedBy",
  },
  {
    key: "Longitude",
    label: "Longitude",
    value: "longitude",
  },
  {
    key: "Latitude",
    label: "Latitude",
    value: "latitude",
  },
  {
    key: "Child Account UID",
    label: "Child Account UID",
    value: "childAccountUID",
  },
  {
    key: "Child Account Name",
    label: "Child Account Name",
    value: "childAccountName",
  },
  {
    key: "Driver ID",
    label: "Driver ID",
    value: "driverID",
  },
  {
    key: "Garmin",
    label: "Garmin",
    value: "garmin",
  },
  {
    key: "Logbook",
    label: "Logbook",
    value: "logbook",
  },
  {
    key: "Panic",
    label: "Panic",
    value: "panic",
  },
  {
    key: "Privacy",
    label: "Privacy",
    value: "privacy",
  },
  {
    key: "Vehicle Diagnostic Port",
    label: "Vehicle Diagnostic Port",
    value: "vehicleDiagnosticPort",
  },
  {
    key: "Device Local Install Date",
    label: "Device Local Install Date",
    value: "deviceLocalInstallDate",
  },
  {
    key: "Device Local Timezone",
    label: "Device Local Timezone",
    value: "deviceLocalTimezone",
  },
  {
    key: "Actual Goods Issue Date",
    label: "Actual Goods Issue Date",
    value: "actualGoodsIssueDate",
  },
  {
    key: "Material Number",
    label: "Material Number",
    value: "materialNumber",
  },
  {
    key: "Immobilization HGV",
    label: "Immobilization HGV",
    value: "immobilizationHGV",
  },
  {
    key: "Immobilization LCV",
    label: "Immobilization LCV",
    value: "immobilizationLCV",
  },
  {
    key: "Competitor Removal",
    label: "Competitor Removal",
    value: "competitorRemoval",
  },
  {
    key: "Install Date Last Modified By",
    label: "Install Date Last Modified By",
    value: "installDateLastModifiedBy",
  },
  {
    key: "Carrier Profile",
    label: "Carrier Profile",
    value: "carrierProfile",
  },
  {
    key: "Carrier Region",
    label: "Carrier Region",
    value: "carrierRegion",
  },
  {
    key: "Install Type",
    label: "Install Type",
    value: "installType",
  },
  {
    key: "Esn type",
    label: "Esn type",
    value: "esnType",
  },
  {
    key: "Contract flexibility v m i warranty status",
    label: "Contract flexibility v m i warranty status",
    value: "contractFlexibilityVMIWarrantyStatus",
  },
  {
    key: "RFID",
    label: "RFID",
    value: "rfid",
  },
  {
    key: "PTO",
    label: "PTO",
    value: "pto",
  },
  {
    key: "ECMD",
    label: "ECMD",
    value: "ecmd",
  },
  {
    key: "Canbus",
    label: "Canbus",
    value: "canbus",
  },
  {
    key: "Error Message",
    label: "Error Message",
    value: "errorMessage",
  },
];

// ******** Vehicle Data *********** //

export const vehicleColumnsForDownloads = [
  {
    label: "Technical Ref Id",
    key: "Technical Ref Id",
    value: "technicalRefId",
  },
  {
    label: "ESN",
    key: "ESN",
    value: "esn",
  },
  {
    label: "Status",
    key: "Status",
    value: "status",
  },
  {
    label: "Work Ticket Number",
    key: "Work Ticket Number",
    value: "workTicketNumber",
  },
  {
    label: "Inbound Shipment Date",
    key: "Inbound Shipment Date",
    value: "inboundShipmentDate",
    isDate: true,
  },
  {
    label: "Activation Date",
    key: "Activation Date",
    value: "activationDate",
    isDate: true,
  },
  {
    label: "Vehicle Tracking Id",
    key: "Vehicle Tracking Id",
    value: "vehicleTrackingId",
  },
  {
    label: "Outbound Shipment Date",
    key: "Outbound Shipment Date",
    value: "outboundShipmentDate",
    isDate: true,
  },
  {
    label: "Outbound Shipment Effective Date",
    key: "Outbound Shipment Effective Date",
    value: "outboundShipmentEffectiveDate",
    isDate: true,
  },
  {
    label: "First Reported Date",
    key: "First Reported Date",
    value: "firstReportedDate",
    isDate: true,
  },
  {
    label: "Vehicle Creation Date",
    key: "Vehicle Creation Date",
    value: "vehicleCreationDate",
    isDate: true,
  },
  {
    label: "Install Date",
    key: "Install Date",
    value: "installDate",
    isDate: true,
  },
  {
    label: "Installation Method",
    key: "Installation Method",
    value: "installationMethod",
  },
  {
    label: "Billingstart Date",
    key: "Billingstart Date",
    value: "billingStartDate",
    isDate: true,
  },
  {
    label: "Location",
    key: "Location",
    value: "location",
  },
  {
    label: "Phone Number",
    key: "Phone Number",
    value: "phoneNumber",
  },
  {
    label: "Self Check In",
    key: "Self Check In",
    value: "selfCheckIn",
  },
  {
    label: "Status",
    key: "Status",
    value: "status",
  },
  {
    label: "State",
    key: "State",
    value: "state",
  },
  {
    label: "Location",
    key: "Location",
    value: "location",
  },
  {
    label: "Carrier",
    key: "Carrier",
    value: "carrier",
  },
  {
    label: "IMEI",
    key: "IMEI",
    value: "imei",
  },
  {
    label: "ICCID",
    key: "ICCID",
    value: "iccid",
  },
  {
    label: "Box Model",
    key: "Box Model",
    value: "boxModel",
  },
  {
    label: "Phone Number",
    key: "Phone Number",
    value: "phoneNumber",
  },
  {
    label: "Vehicle Reg Number",
    key: "Vehicle Reg Number",
    value: "vehicleRegNumber",
  },
  {
    label: "Vehicle Label",
    key: "Vehicle Label",
    value: "vehicleLabel",
  },
  {
    label: "Vehicle Make",
    key: "Vehicle Make",
    value: "vehicleMake",
  },
  {
    label: "Vehicle Model",
    key: "Vehicle Model",
    value: "vehicleModel",
  },
  {
    label: "Delivery Number",
    key: "Delivery Number",
    value: "deliveryNumber",
  },
  {
    label: "SFDC Case Number",
    key: "SFDC Case Number",
    value: "sfdcCaseNumber",
  },
  {
    label: "Tracking Number",
    key: "Tracking Number",
    value: "trackingNumber",
  },
  {
    label: "Shipment Carrier",
    key: "Shipment Carrier",
    value: "shipmentCarrier",
  },
  {
    label: "Created At",
    key: "Created At",
    value: "createdAt",
    isDate: true,
  },
  {
    label: "Created By",
    key: "Created By",
    value: "createdBy",
  },
  {
    label: "Modified At",
    key: "Modified At",
    value: "modifiedAt",
    isDate: true,
  },
  {
    label: "Modified By",
    key: "Modified By",
    value: "modifiedBy",
  },
  {
    label: "Old ESN",
    key: "Old ESN",
    value: "oldEsn",
  },
  {
    label: "New ESN",
    key: "New ESN",
    value: "newEsn",
  },
  {
    label: "Swap Date",
    key: "Swap Date",
    value: "swapDate",
    isDate: true,
  },
  {
    label: "Check In Validated On",
    key: "Check In Validated On",
    value: "checkInValidatedOn",
    isDate: true,
  },
  {
    label: "Swap Reason",
    key: "Swap Reason",
    value: "swapReason",
  },
  {
    label: "Unit Type",
    key: "Unit Type",
    value: "unitType",
  },
  {
    label: "Last Reported Date",
    key: "Last Reported Date",
    value: "lastReportedDate",
    isDate: true,
  },
  {
    label: "First Reported Date",
    key: "First Reported Date",
    value: "firstReportedDate",
  },
  {
    label: "Carrier Rate Plan",
    key: "Carrier Rate Plan",
    value: "carrierRatePlan",
  },
  {
    label: "Batch Number",
    key: "Batch Number",
    value: "batchNumber",
  },
  {
    label: "Batch File Name",
    key: "Batch File Name",
    value: "batchFileName",
  },
  {
    label: "MEID",
    key: "MEID",
    value: "meid",
  },
  {
    label: "Serial Num",
    key: "Serial Num",
    value: "serialNum",
  },
  {
    label: "Box Name",
    key: "Box Name",
    value: "boxName",
  },
  {
    label: "Sensor Names",
    key: "Sensor Names",
    value: "sensorNames",
  },
  {
    label: "Sensors",
    key: "Sensors",
    value: "sensors",
  },
  {
    label: "VIN",
    key: "VIN",
    value: "vin",
  },
  {
    label: "Vehicle Serial No",
    key: "Vehicle Serial No",
    value: "vehicleSerialNumber",
  },
  {
    label: "Vehicle year",
    key: "Vehicle year",
    value: "vehicleYear",
  },
  {
    label: "Odometer",
    key: "Odometer",
    value: "odometer",
  },
  {
    label: "Odometer Units",
    key: "Odometer Units",
    value: "odometerUnits",
  },
  {
    label: "Engine On",
    key: "Engine On",
    value: "engineOn",
  },
  {
    label: "Fuel type",
    key: "Fuel type",
    value: "fuelType",
  },
  {
    label: "Vehicle Size",
    key: "Vehicle Size",
    value: "vehicleSize",
  },
  {
    label: "Account UID",
    key: "Account UID",
    value: "accountUID",
  },
  {
    label: "Businness Partner id",
    key: "Businness Partner id",
    value: "businessPartnerID",
  },
  {
    label: "Sales Order Number",
    key: "Sales Order Number",
    value: "salesOrderNumber",
  },
  {
    label: "Work Order Number",
    key: "Work Order Number",
    value: "workOrderNumber",
  },
  {
    label: "Purchase Order Number",
    key: "Purchase Order Number",
    value: "purchaseOrderNumber",
  },
  {
    label: "Install Date SSO",
    key: "Install Date SSO",
    value: "installDateSSO",
    isDate: true,
  },
  {
    label: "Activation Requested On",
    key: "Activation Requested On",
    value: "activationRequestedOn",
  },
  {
    label: "Installation Notes",
    key: "Installation Notes",
    value: "installationNotes",
  },
  {
    label: "Install Date",
    key: "Install Date",
    value: "installDate",
  },
  {
    label: "Check In Validated",
    key: "Check In Validated",
    value: "checkInValidated",
  },
  {
    label: "Check In Complet",
    key: "Check In Complet",
    value: "checkInComplete",
  },
  {
    label: "Last Reported From",
    key: "Last Reported From",
    value: "lastReportedFrom",
  },
  {
    label: "Account Name",
    key: "Account Name",
    value: "accountName",
  },
  {
    label: "Installer Name",
    key: "Installer Name",
    value: "installerName",
  },
  {
    label: "Installer Email",
    key: "Installer Email",
    value: "installerEmail",
  },
  {
    label: "Installation Company",
    key: "Installation Company",
    value: "installationCompany",
  },
  {
    label: "Installed By",
    key: "Installed By",
    value: "installedBy",
  },
  {
    label: "Longitude",
    key: "Longitude",
    value: "longitude",
  },
  {
    label: "Latitude",
    key: "Latitude",
    value: "latitude",
  },
  {
    label: "Child Account UID",
    key: "Child Account UID",
    value: "childAccountUID",
  },
  {
    label: "Child Account Name",
    key: "Child Account Name",
    value: "childAccountName",
  },
  {
    label: "Driver Id",
    key: "Driver Id",
    value: "driverID",
  },
  {
    label: "Garmin",
    key: "Garmin",
    value: "garmin",
  },
  {
    label: "Logbook",
    key: "Logbook",
    value: "logbook",
  },
  {
    label: "Panic",
    key: "Panic",
    value: "panic",
  },
  {
    label: "Privacy",
    key: "Privacy",
    value: "privacy",
  },
  {
    label: "Vehicle Diagnostic Port",
    key: "Vehicle Diagnostic Port",
    value: "vehicleDiagnosticPort",
  },
  {
    label: "Device Local Install Date",
    key: "Device Local Install Date",
    value: "deviceLocalInstallDate",
  },
  {
    label: "Device Local Time Zone",
    key: "Device Local Time Zone",
    value: "deviceLocalTimezone",
  },
  {
    label: "Actual Goods Issue Date",
    key: "Actual Goods Issue Date",
    value: "actualGoodsIssueDate",
  },
  {
    label: "Material Number",
    key: "Material Number",
    value: "materialNumber",
  },
  {
    label: "Immobilization HGV",
    key: "Immobilization HGV",
    value: "immobilizationHGV",
  },
  {
    label: "Immobilization LCV",
    key: "Immobilization LCV",
    value: "immobilizationLCV",
  },
  {
    label: "Competitor Removal",
    key: "Competitor Removal",
    value: "competitorRemoval",
  },
  {
    label: "Install Date Last Modified By",
    key: "Install Date Last Modified By",
    value: "installDateLastModifiedBy",
  },
  {
    label: "Carrier Profile",
    key: "Carrier Profile",
    value: "carrierProfile",
  },
  {
    label: "Carrier Region",
    key: "Carrier Region",
    value: "carrierRegion",
  },
  {
    label: "Install Type",
    key: "Install Type",
    value: "installType",
  },
  {
    label: "ESN Type",
    key: "ESN Type",
    value: "esnType",
  },
  {
    label: "Contract Flexibility VMI Warranty Status",
    key: "Contract Flexibility VMI Warranty Status",
    value: "contractFlexibilityVMIWarrantyStatus",
  },
  {
    label: "RFID",
    key: "RFID",
    value: "rfid",
  },
  {
    label: "PTO",
    key: "PTO",
    value: "pto",
  },
  {
    label: "ECMD",
    key: "ECMD",
    value: "ecmd",
  },
  {
    label: "Can Bus",
    key: "Can Bus",
    value: "canbus",
  },
  {
    label: "Error Message",
    key: "Error Message",
    value: "errorMessage",
  },
];
