import { Comments, OcComments } from "../../api/accounts/url";
import { BuyoutStatus, MilestoneDetails } from "../../api/orders/url";
import { postRequest } from "../RestServices";

export async function getMilestoneData(opportunityId) {
  // return response1;
  const request = {
    slOppId: opportunityId,
  };
  try {
    return await postRequest(MilestoneDetails, request);
  } catch (err) {
    return {
      error: err,
    };
  }
}

export async function getBuyoutStatus(opportunityId) {
  const request = {
    sfOppId: opportunityId,
  };
  try {
    return await postRequest(BuyoutStatus, request);
  } catch (err) {
    return {
      error: err,
    };
  }
}

export async function postComment(data) {
  try {
    return await postRequest(Comments, data);
  } catch (err) {
    return err;
  }
}

export async function postCommentOc(data) {
  try {
    return await postRequest(OcComments, data);
  } catch (err) {
    return err;
  }
}
