import { createContext, useState } from "react";

export const AccountDetailsContext = createContext();
export const AccountDetailsProvider = (props) => {
  const [accountId, setAccountId] = useState("");
  const [source, setSource] = useState("");
  const [accountDetails, setAccountDetails] = useState({
    customerName: "",
    customerNumber: "",
    vztSegment: "",
    sfdcUsername: "",
    contractAccount: [],
    erpBpNumber: "",
    erpPrimaryContact: "",
    resellerBusinessPartnerNumber: "",
    employeeId: "",
    employeeFullName: "",
    employeeEmail: "",
    providerOrder: "",
    division: "",
    distributionChannel: "",
    salesOrgId: "",
    salesOrgName: "",
    billingCountry: "",
    accountBrand: "",
    mapUrl: "",
    currencyCode: "",
  });
  const [jobs, setJobs] = useState([]);
  const [cspDetails, setCspDetails] = useState([]);
  const [caseDetails, setCaseDetails] = useState([]);
  const [products, setProducts] = useState([]);
  const [jobDurationDetails, setJobDurationDetails] = useState([]);
  const [jobLengthHours, setJobLengthHours] = useState(0);
  const [jobLengthMinutes, setJobLengthMinutes] = useState(0);
  const [historicalAddressDetails, setHistoricalAddressDetails] = useState([]);
  const [specialItemFeeDesc, setSpecialItemFeeDesc] = useState([]);
  const [installPartnerFeesDesc, setInstallPartnerFeesDesc] = useState([]);
  const [customerFeeDesc, setCustomerFeeDesc] = useState([]);
  // const [chargeableStates, setChargeableStates] = useState([]);
  const [retrieveMaterials, setRetrieveMaterials] = useState([]);
  const [specialItemsFees, setSpecialItemsFees] = useState([]);
  const [installPartnerFees, setInstallPartnerFees] = useState([]);
  const [customerFees, setCustomerFees] = useState([]);
  const [totalCustomerFees, setTotalCustomerFees] = useState(0);
  const [OneERPStatus, setOneERPStatus] = useState([]);
  const [OneERPSubStatus, setOneERPSubStatus] = useState([]);
  const [countryListDetails, setCountryListDetails] = useState(null);
  const [stateListDetails, setStateListDetails] = useState(null);
  const [addFeesMaterialsList, setAddFeesMaterialsList] = useState([]);

  return (
    <AccountDetailsContext.Provider
      value={{
        accountId: { accountId, setAccountId },
        source: { source, setSource },
        accountDetails: { ...accountDetails, setAccountDetails },
        jobs: { jobs, setJobs },
        cspDetails: { cspDetails, setCspDetails },
        caseDetails: { caseDetails, setCaseDetails },
        products: { products, setProducts },
        jobDurationDetails: { jobDurationDetails, setJobDurationDetails },
        jobLengthHours: { jobLengthHours, setJobLengthHours },
        jobLengthMinutes: { jobLengthMinutes, setJobLengthMinutes },
        historicalAddressDetails: { historicalAddressDetails, setHistoricalAddressDetails },
        specialItemFeeDesc: { specialItemFeeDesc, setSpecialItemFeeDesc },
        installPartnerFeesDesc: { installPartnerFeesDesc, setInstallPartnerFeesDesc },
        customerFeeDesc: { customerFeeDesc, setCustomerFeeDesc },
        // chargeableStates: { chargeableStates, setChargeableStates },
        customerFees: { customerFees, setCustomerFees },
        specialItemsFees: { specialItemsFees, setSpecialItemsFees },
        installPartnerFees: { installPartnerFees, setInstallPartnerFees },
        totalCustomerFees: { totalCustomerFees, setTotalCustomerFees },
        OneERPStatus: { OneERPStatus, setOneERPStatus },
        OneERPSubStatus: { OneERPSubStatus, setOneERPSubStatus },
        retrieveMaterials: { retrieveMaterials, setRetrieveMaterials },
        countryListDetails: { countryListDetails, setCountryListDetails },
        stateListDetails: { stateListDetails, setStateListDetails },
        addFeesMaterialsList: { addFeesMaterialsList, setAddFeesMaterialsList },
      }}
    >
      {props.children}
    </AccountDetailsContext.Provider>
  );
};
