import { lazy, useState, useContext, useEffect } from "react";
import styled from "@emotion/styled";
import { Button } from "@vds/buttons";
import { Notification } from "@vds/notifications";
import { CompactCol } from "../../components/core/Utility";
import { Grid, Row } from "@vds/grids";
import { BOXTYPE, MODEL, UNITTYPE } from "../../constants/UploadUnitsConstants";
import { SLDropDown, Header } from "../../components/core/Controls";
import { updateBatchInfo } from "../../services/Aggregator/updateBatchInfo";
import { UploadContext } from "../../context/uploadContext";
import { resetTab } from "../../utility/navigation";
import { getProfilesForAtt } from "../../services/updateActiveUnits/getProfilesForAtt";
import { getProfilesForVodafone } from "../../services/updateActiveUnits/getProfilesForVodafone";
import { getRatePlansForVerizon } from "../../services/updateActiveUnits/getRatePlansForVerizon";
import { Loader } from "@vds/loaders";

const ContentWrapper = styled.div`
  padding: 20px 12px;
`;
const carriers = {
  Verizon: "Verizon",
  ATT: "ATT",
  Vodafone: "Vodafone",
  Telstra: "Telstra",
  KORE: "KORE",
};

const attRatePlan = [
  { key: "Telogis - 1MB Intl Plan", value: "Telogis - 1MB Intl Plan" },
  { key: "Telogis - 25MB Intl Plan", value: "Telogis - 25MB Intl Plan" },
  { key: "Telogis - 100MB Intl Plan", value: "Telogis - 100MB Intl Plan" },
  { key: "Fleetmatics 1MB Pooled 3G Plan", value: "FLTK" },
  { key: "Fleetmatics NA 3MB Pooled Plan", value: "FL03" },
];

const verizonRegion = [
  { key: "US", value: "US" },
  { key: "Canada", value: "Canada" },
];

const UpdateBatch = ({ country }) => {
  const [boxType, setBoxType] = useState("Select Box Type");
  const [model, setModel] = useState("Select Model");
  const [unitType, setUnitType] = useState("Select Unit Type");
  const { updateBatchNumber, esns, duplicateEsns } = useContext(UploadContext);
  const { updateBatchNumber: batchNumberValue } = updateBatchNumber;
  const [notification, setNotification] = useState();
  const { esns: esnsForNotify } = esns;
  const { duplicateEsns: duplicatedEsns } = duplicateEsns;
  const [loading, setLoading] = useState(false);
  const [attProfile, setAttProfile] = useState();
  const [verizonRatePlan, setVerizonRatePlan] = useState();
  const [vodafoneProfile, setVodafoneProfile] = useState();
  const [selectedCarrier, setSelectedCarrier] = useState();
  const [selectedProfile, setSelectedProfile] = useState();
  const [selectedRatePlan, setSelectedRatePlan] = useState();
  const [selectedRegion, setSelectedRegion] = useState();

  const handleBoxType = (event) => {
    setBoxType(event.target.value);
  };

  const handleUnitType = (event) => {
    setUnitType(event.target.value);
  };

  const handleModel = (event) => {
    setModel(event.target.value);
  };

  const updateBatch = () => {
    if (boxType === "Select Box Type" || model === "Select Model" || unitType === "Select Unit Type") {
      notify("Unable to Update! Please select valid details", "error");
    } else {
      const config = {
        batchNumber: batchNumberValue,
        boxType: boxType,
        boxModel: model,
        unitType: unitType,
        carrier: selectedCarrier,
        ratePlan: selectedRatePlan,
        profile: selectedProfile,
      };
      if (selectedCarrier === carriers.Verizon && checkVerizonVDDOrEATRatePlan()) {
        config.region = selectedRegion;
      }
      setLoading(true);
      updateBatchInfo(config).then((response) => {
        if (response === 200) {
          notify("Updated Successfully!", "success");
          resetTab();
        } else {
          notify("Unable to Update.Please try again later!", "error");
        }
        setLoading(false);
      });
      setBoxType("Select Box Type");
      setModel("Select Model");
      setUnitType("Select Unit Type");
    }
  };

  const notify = (content, type) => {
    setNotification(
      <>
        <Notification type={type} title={content} fullBleed={true} onCloseButtonClick={() => notificationHandler()} />
        <br />
      </>
    );
  };

  const notificationHandler = () => {
    setBoxType("Select Box Type");
    setModel("Select Model");
    setUnitType("Select Unit Type");
  };

  const getProfilesAndRatePlans = async () => {
    setLoading(true);
    let failedItems = [];
    const verizonResponse = await getRatePlansForVerizon();
    if (verizonResponse.status === 200) {
      setVerizonRatePlan(verizonResponse.data.data);
    } else {
      failedItems.push("Verizon");
    }
    const attResponse = await getProfilesForAtt();
    if (attResponse.status === 200) {
      setAttProfile(attResponse.data.data);
    } else {
      failedItems.push("ATT");
    }
    const vodafoneResponse = await getProfilesForVodafone();
    if (vodafoneResponse.status === 200) {
      setVodafoneProfile(
        vodafoneResponse.data.data.map((item) => ({
          Profile_Name: item.customerServiceProfileName,
        }))
      );
    } else {
      failedItems.push("Vodafone");
    }
    if (failedItems.length > 0) {
      let message = failedItems.shift();

      failedItems.forEach((item, index) => {
        if (index === failedItems.length - 1) {
          message += " and ";
        } else {
          message += ", ";
        }
        message += item;
      });
      notify(`Error fetching profiles for ${message}`, "error");
    }
    setLoading(false);
  };

  useEffect(() => {
    if (duplicatedEsns != undefined && duplicatedEsns.length <= 5) {
      notify(
        `${esnsForNotify.length} of ${esnsForNotify.length + duplicatedEsns.length} Units uploaded successfully!\n
      The following esn's are already uploaded \n
      ${duplicatedEsns}`,
        "success"
      );
    } else if (duplicatedEsns != undefined && duplicatedEsns.length > 5) {
      notify(
        `${esnsForNotify.length} of ${esnsForNotify.length + duplicatedEsns.length} Units uploaded successfully! ${
          duplicatedEsns.length
        } are already uploaded in db!`,
        "success"
      );
    } else {
      notify(`${esnsForNotify.length} of ${esnsForNotify.length} Units uploaded successfully!`, "success");
    }
    getProfilesAndRatePlans();
  }, []);

  const carrierChangeCallback = (event) => {
    setSelectedCarrier(event.target.value);
    setSelectedProfile(null);
    setSelectedRatePlan(null);
    setSelectedRegion(null);
  };

  const checkValidCountryForCarrierSelection = () => {
    if (country === "USA" || country === "MEX" || country === "CAN") {
      return true;
    }
    return false;
  };

  const getCarrierDropdown = () => {
    if (!checkValidCountryForCarrierSelection()) {
      return null;
    }
    return (
      <SLDropDown error={false} value={selectedCarrier} width="100%" label="Select Carrier" onChange={carrierChangeCallback}>
        <option key={-1}></option>
        {Object.entries(carriers).map((carrier) => {
          return (
            <option key={carrier[0]} value={carrier[0]}>
              {carrier[1]}
            </option>
          );
        })}
      </SLDropDown>
    );
  };

  const getRatePlanForSelectedCarrier = () => {
    switch (selectedCarrier) {
      case carriers.ATT:
        return attRatePlan;
      case carriers.Verizon:
        return verizonRatePlan;
      default:
        return null;
    }
  };

  const getRatePlanDropdown = () => {
    const ratePlan = getRatePlanForSelectedCarrier();
    return (
      ratePlan?.length > 0 && (
        <SLDropDown
          error={false}
          width="100%"
          label="Select Rate Plan"
          value={selectedRatePlan}
          onChange={(e) => {
            setSelectedRatePlan(e.target.value);
          }}
        >
          <option key={-1}></option>
          {ratePlan.map((rateplan) => {
            return (
              <option key={rateplan.key} value={rateplan.value}>
                {rateplan.key}
              </option>
            );
          })}
        </SLDropDown>
      )
    );
  };

  const checkVerizonVDDOrEATRatePlan = () => {
    if (selectedRatePlan === "4gMeridianPlan" || selectedRatePlan === "98126 4g VZC Plan") {
      return true;
    }
    return false;
  };

  const getRegionDropdown = () => {
    return (
      selectedCarrier === carriers.Verizon &&
      checkVerizonVDDOrEATRatePlan() && (
        <SLDropDown
          error={false}
          width="100%"
          label="Select Region"
          onChange={(e) => {
            setSelectedRegion(e.target.value);
          }}
          value={selectedRegion}
        >
          <option key={-1}></option>
          {verizonRegion.map((region) => {
            return (
              <option key={region.key} value={region.value}>
                {region.key}
              </option>
            );
          })}
        </SLDropDown>
      )
    );
  };

  const getProfileForSelectedCarrier = () => {
    switch (selectedCarrier) {
      case carriers.ATT:
        return attProfile;
      case carriers.Vodafone:
        return vodafoneProfile;
      default:
        return null;
    }
  };

  const getProfileDropdown = () => {
    const profiles = getProfileForSelectedCarrier();
    return (
      profiles?.length > 0 && (
        <SLDropDown error={false} width="100%" label="Select Profiles" value={selectedProfile} onChange={(e) => setSelectedProfile(e.target.value)}>
          <option key={-1}></option>
          {profiles.map((profile) => {
            return (
              <option key={profile.Profile_Name} value={profile.Profile_Name}>
                {profile.Profile_Name}
              </option>
            );
          })}
        </SLDropDown>
      )
    );
  };

  return (
    <>
      <Loader active={loading} />
      <Header>Update Batch</Header>
      <ContentWrapper>
        {notification}
        <Grid>
          <Row>
            <Notification
              type="info"
              title="Please select the correct information matching to your unit below"
              hideCloseButton={true}
              inline={true}
            ></Notification>
            <CompactCol colSizes={{ mobile: 6, tablet: 6, desktop: 6 }}>
              <SLDropDown label="Box Type" onChange={handleBoxType}>
                {Object.keys(BOXTYPE).map((downloadFile, index) => {
                  return (
                    <option key={index} value={BOXTYPE[downloadFile]}>
                      {BOXTYPE[downloadFile]}
                    </option>
                  );
                })}
              </SLDropDown>
              <SLDropDown label="Unit Type" onChange={handleUnitType}>
                {Object.keys(UNITTYPE).map((downloadFile, index) => {
                  return (
                    <option key={index} value={UNITTYPE[downloadFile]}>
                      {UNITTYPE[downloadFile]}
                    </option>
                  );
                })}
              </SLDropDown>
              <SLDropDown label="Model" onChange={handleModel}>
                {Object.keys(MODEL).map((downloadFile, index) => {
                  return (
                    <option key={index} value={MODEL[downloadFile]}>
                      {MODEL[downloadFile]}
                    </option>
                  );
                })}
              </SLDropDown>
              {getCarrierDropdown()}
              {getRatePlanDropdown()}
              {getRegionDropdown()}
              {getProfileDropdown()}
              <Button size="small" secondary={false} onClick={updateBatch}>
                Update
              </Button>
            </CompactCol>
            <CompactCol colSizes={{ mobile: 6, tablet: 6, desktop: 6 }}></CompactCol>
          </Row>
        </Grid>
      </ContentWrapper>
    </>
  );
};

export default UpdateBatch;

export const UpdateBatchData = {
  name: "Update Batch",
  id: "updatebatch",
  component: lazy(() => import("./UpdateBatch")),
  route: "/updatebatch",
};
