const reportsUrl = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REPORTS}`;
const accountsURL = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ACCOUNTS}`;
export const ReportsOpportunityHistory = `${reportsUrl}/opportunities`;
export const ReportsDeviceData = `${reportsUrl}/devices`;
export const ReportsVTUData = `${reportsUrl}/devices/vtus`;
export const ReportsCameraData = `${reportsUrl}/devices/cameras`;
export const ReportsMilestoneDetails = `${reportsUrl}/opportunityanddevicedetails`;
export const getShipmentEvents = `${accountsURL}/shipment/events`;

export const AUDIT_LOGS = `${reportsUrl}/auditlogs`;
